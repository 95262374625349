const headings = Array.from($($('.article-content-core > h1, .article-content-core > h2, .article-content-core > h3, .article-content-core > h4, .article-content-core > h5, .article-content-core > h6')))
const tocTree = document.querySelector(".toc-tree");
const ul = document.createElement("ul");
let toc = "";
let level = 1;
let tocList = "";

tocTree.appendChild(ul);

headings.map((heading) => {
toc =
  heading.outerHTML.replace(
    /<h([\d]) id=([^<]+)>([^]+)<\/h([\d])>/gi,
    function (str, openLevel, id, titleText, closeLevel) {
      if (openLevel !== closeLevel) {
        return str;
      }
      if (openLevel == level) {
      } else if (openLevel > level) {
        tocList += (new Array(openLevel - level + 1)).join('<ul>');
      } else if (openLevel < level) {
        tocList += (new Array(level - openLevel + 1)).join('</ul>');
      } else {
        tocList += (new Array(level + 1)).join('</li>');
      }

      level = parseInt(openLevel);

      tocList += '<li><a href="#' + heading.id + '">' + titleText + '</a></li>';

      return '<h' + openLevel + '><a href="#' + heading.id + '" id="' + heading.id + '">' + titleText + '</a></h' + closeLevel + '>';
    }
  );
});
ul.insertAdjacentHTML("beforeend", tocList)

const tocAnchors = tocTree.querySelectorAll("a");
const obFunc = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const index = headings.indexOf(entry.target);
            tocAnchors.forEach((tab) => {
                tab.classList.remove("active");
            });
            tocAnchors[index].classList.add("active");
        }
    });
};
const obOption = {

  rootMargin: "0px 0px -80% 0px",
  threshold: 0
};
const observer = new IntersectionObserver(obFunc, obOption);
headings.forEach((headings) => observer.observe(headings));
